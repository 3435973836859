import React from 'react'
import { Link as GLink } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { Card, Box, Divider, Link } from 'theme-ui'
import Section from '@components/Section'

const styles = {
  imageWrapper: ({ width, height }) => ({
    width: `full`,
    maxWidth: width,
    maxHeight: height,
    borderRadius: `default`,
    overflow: `hidden`,
  }),
  caption: {
    display: `block`,
    textAlign: `center`,
    fontSize: 0,
    mt: 1,
  },
  image: {
    maxWidth: `100%`,
    height: `auto`,
    borderRadius: `0.5rem`,
  },
  padding: {
    padding: 3,
  },
}

const Poster = (props) => {
  const data = useStaticQuery(spQuery)
  const { banner, sponsors } = data
  const sp = sponsors.nodes[0]
  let sponsor, sLink

  switch (props.no) {
    case 2:
      sponsor = sp.sponsor2
      sLink = sp.sLink2
      break
    case 3:
      sponsor = sp.sponsor3
      sLink = sp.sLink3
      break
    case 4:
      sponsor = sp.sponsor4
      sLink = sp.sLink4
      break
    case 5:
      sponsor = sp.sponsor5
      sLink = sp.sLink5
      break
    default:
      sponsor = sp.sponsor1
      sLink = sp.sLink1
      break
  }

  const { fluid } = (banner && banner.childImageSharp) || {}
  if (!fluid) return null

  const width = `400px`
  const height = `400px`

  return (
    <Section>
      <Card variant='paper' sx={styles.padding}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={styles.imageWrapper({ width, height })}>
            <Link variant='mute' as={GLink} to={sLink} target='_blank'>
              <img
                src={sponsor.asset.fluid.src}
                style={styles.image}
                alt='sponsorlu icerik'
              />
            </Link>
          </Box>
          <Link
            variant='mute'
            as={GLink}
            to={sLink}
            target='_blank'
            sx={styles.caption}
          >
            ZİYARET EDİN
          </Link>
        </div>
      </Card>
    </Section>
  )
}

const spQuery = graphql`
  query spQuery {
    banner: file(absolutePath: { regex: "/sponsor.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 342, maxHeight: 260, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
    sponsors: allSanityLive {
      nodes {
        sponsor1 {
          asset {
            fluid {
              src
            }
          }
        }
        sLink1
        sponsor2 {
          asset {
            fluid {
              src
            }
          }
        }
        sLink2
        sponsor3 {
          asset {
            fluid {
              src
            }
          }
        }
        sLink3
        sponsor4 {
          asset {
            fluid {
              src
            }
          }
        }
        sLink4
        sponsor5 {
          asset {
            fluid {
              src
            }
          }
        }
        sLink5
      }
    }
  }
`

export default Poster
